@font-face {
  font-family: "Raleway-Regular";
  src: url("../../public/assets/fonts/Raleway-Regular.ttf");
}

@font-face {
  font-family: "Raleway-Medium";
  src: url("../../public/assets/fonts/Raleway-Medium.ttf");
}

@font-face {
  font-family: "Raleway-SemiBold";
  src: url("../../public/assets/fonts/Raleway-SemiBold.ttf");
}

@font-face {
  font-family: "Raleway-Bold";
  src: url("../../public/assets/fonts/Raleway-Bold.ttf");
}
