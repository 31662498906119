@import "theme/colors.scss";
@import "theme/sizes.scss";

.background-loader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: map-get($colors, modal-page-background);
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader-gif {
    width: 300px;
  }
}

@media only screen and (max-width: 440px) {
  .background-loader {
    .loader-gif {
      width: vw-mobile(300);
    }
  }
}
