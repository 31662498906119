@import 'theme/sizes.scss';

.app-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  .app-main-content {
    width: calc(100% - vw(248));
    @media only screen and (max-width: 1650px) {
      width: calc(100% - 214px);
    }
    @media only screen and (max-width: 950px) {
      width: 100%;
    }
  }
  .app-main-mini-content {
    width: calc(100% - vw(82));
    @media only screen and (max-width: 1650px) {
      width: calc(100% - 70px);
    }
    @media only screen and (max-width: 950px) {
      width: 100%;
    }
  }
}
