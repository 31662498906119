@import url(theme/fonts.scss);
@import "theme/sizes.scss";
// @import 'theme/colors.scss';

.failed-to-load-message {
  font-size: "0.875rem";
  margin-top: "2rem";
}

.custom-button-failed-load {
  width: "10.6rem";
  height: "2.25rem";
  margin-top: "2rem";
}
